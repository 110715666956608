import React, { useState } from 'react';
import { Container, Modal, Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import RemoteTable from '../RemoteTable';

export default function ListaCodigoCadastro() {
  const [showModal, setShowModal] = useState(false);
  const [editando, setEditando] = useState(false);
  const [codigoEdit, setCodigoEdit] = useState({
    id: '',
    codigo: '',
    pontos: '',
    usos: '',
    expiraEm: ''
  });
  const [refreshKey, setRefreshKey] = useState(0);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [codigoSelecionado, setCodigoSelecionado] = useState('');

  const columns = [
    {
      text: 'ID',
      dataField: 'id',
      sort: true,
      hidden: true
    },
    {
      text: 'Código',
      dataField: 'codigo',
      sort: true,
    },
    {
      text: 'Pontos',
      dataField: 'pontos',
      sort: true,
      formatter: (cell) => Number(cell).toLocaleString('pt-BR')
    },
    {
      text: 'Usos Permitidos',
      dataField: 'usos',
      sort: true,
    },
    {
      text: 'Usos Realizados',
      dataField: 'usosRealizados',
      sort: true,
      formatter: (cell) => Number(cell).toLocaleString('pt-BR')
    },
    {
      text: 'Expira em',
      dataField: 'expiraEm',
      sort: true,
      formatter: (cell) => cell ? moment(cell).format('DD/MM/YYYY') : 'Sem expiração'
    },
    {
      text: 'Compartilhar',
      dataField: 'id',
      formatter: (cell, row) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => handleShowLink(row.codigo)}
        >
          <i className="fas fa-share-alt"></i>
        </button>
      ),
    },
    {
      text: 'Editar',
      dataField: 'id',
      formatter: (cell, row) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => handleEdit(row)}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      text: 'Excluir',
      dataField: 'id',
      formatter: (cell) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => handleDelete(cell)}
        >
          <i className="fas fa-times f-red"></i>
        </button>
      ),
    },
  ];

  const handleEdit = (codigo) => {
    setCodigoEdit({
      id: codigo.id,
      codigo: codigo.codigo,
      pontos: codigo.pontos,
      usos: codigo.usos,
      expiraEm: codigo.expiraEm ? moment(codigo.expiraEm).format('YYYY-MM-DD') : ''
    });
    setShowModal(true);
  };

  const forceRefresh = () => {
    setRefreshKey(old => old + 1);
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Tem certeza que deseja excluir este código?')) {
      return;
    }

    try {
      await api.delete(`/api/codigos-cadastro/${id}`);
      
      toast.success('Código excluído com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });

      forceRefresh();
    } catch (error) {
      toast.error(error.response?.data?.erro || 'Erro ao excluir código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
  };

  const handleSaveEdit = async () => {
    setEditando(true);
    try {
      await api.put(`/api/codigos-cadastro/${codigoEdit.id}`, {
        codigo: codigoEdit.codigo,
        pontos: Number(codigoEdit.pontos),
        usos: Number(codigoEdit.usos),
        expiraEm: codigoEdit.expiraEm
      });

      toast.success('Código atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });

      setShowModal(false);
      forceRefresh();
    } catch (error) {
      toast.error(error.response?.data?.erro || 'Erro ao atualizar código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
    setEditando(false);
  };

  const handleShowLink = (codigo) => {
    setCodigoSelecionado(codigo);
    setShowLinkModal(true);
  };

  const handleCopyLink = () => {
    const link = `https://clubepropintor.com.br/cadastrar?codigo_cadastro=${codigoSelecionado}`;
    navigator.clipboard.writeText(link);
    toast.success('Link copiado com sucesso!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
    });
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Códigos Cadastrados</h4>
      <hr className="hr-loja mt-0 mb-4" />

      <RemoteTable
        key={refreshKey}
        dataId="id"
        url="/api/codigos-cadastro"
        colunas={columns}
        ordenacaoInicial={{ campo: 'criadoEm', direcao: 'desc' }}
      />

      <Modal show={showModal} onHide={() => setShowModal(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Editar Código</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Código</Form.Label>
              <Form.Control
                type="text"
                value={codigoEdit.codigo}
                onChange={(e) => setCodigoEdit({...codigoEdit, codigo: e.target.value.toUpperCase()})}
                className="input-theme"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Pontos</Form.Label>
              <Form.Control
                type="number"
                value={codigoEdit.pontos}
                onChange={(e) => setCodigoEdit({...codigoEdit, pontos: e.target.value})}
                min="1"
                className="input-theme"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Usos Permitidos</Form.Label>
              <Form.Control
                type="number"
                value={codigoEdit.usos}
                onChange={(e) => setCodigoEdit({...codigoEdit, usos: e.target.value})}
                min="1"
                className="input-theme"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Data de Expiração</Form.Label>
              <Form.Control
                type="date"
                value={codigoEdit.expiraEm}
                onChange={(e) => setCodigoEdit({...codigoEdit, expiraEm: e.target.value})}
                min={moment().format('YYYY-MM-DD')}
                max="2999-12-31"
                className="input-theme"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button 
            className="btn-vermelho" 
            onClick={() => setShowModal(false)}
            disabled={editando}
          >
            Cancelar
          </button>
          <button 
            className="btn-primario" 
            onClick={handleSaveEdit}
            disabled={editando}
          >
            {editando ? 'Salvando...' : 'Salvar'}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLinkModal} onHide={() => setShowLinkModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Link para Cadastro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              value={`https://clubepropintor.com.br/cadastrar?codigo_cadastro=${codigoSelecionado}`}
              readOnly
              className="input-theme"
            />
            <button
              className="btn-primario ms-2"
              onClick={handleCopyLink}
            >
              <i className="fas fa-copy"></i>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
} 