/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import RemoteTable from '../RemoteTable';
import capitalize from '../../../utils/capitalize';
import historico from '../../../services/Historico';

export default function PontuacoesCodigo() {
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, 'days').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [filtros, setFiltros] = useState({
    dataFinal: moment().toISOString().substr(0, 10),
    dataInicial: moment().subtract(7, 'days').toISOString().substr(0, 10),
  });

  const setarFiltros = (e) => {
    e.preventDefault();
    setFiltros({
      dataInicial,
      dataFinal,
    });
  };

  const columns = [
    {
      text: 'ID',
      dataField: 'id',
      sort: true,
      hidden: true
    },
    {
      text: 'Código',
      dataField: 'codigo',
      sort: true,
    },
    {
      text: 'Pintor',
      dataField: 'parceiro.nome',
      sort: true,
      formatter: (celula, valor) => {
        const nome = valor['parceiro.nome'];
        const cpf = valor['parceiro.cpf'];
        return nome ? (
          <button
            className="btn-limpo"
            onClick={(e) => {
              e.preventDefault();
              historico.push(`/painel/pintor/${cpf}`);
            }}
          >
            <b>{capitalize(nome)}</b>
          </button>
        ) : (
          ''
        );
      },
    },
    {
      text: 'CPF',
      dataField: 'parceiro.cpf',
      formatter: (celula, valor) => {
        const cpf = valor['parceiro.cpf'];
        return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') || '';
      },
      sort: true,
    },
    {
      text: 'Pontos',
      dataField: 'pontos',
      formatter: (celula, valor) => {
        return Number(valor.pontos).toLocaleString('pt-BR');
      },
      sort: true,
    },
    {
      text: 'Data de Utilização',
      dataField: 'dataUtilizacao',
      formatter: (celula, valor) => {
        return moment(valor.dataUtilizacao).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    }
  ];

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações por Código</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme"
            onChange={(e) => setDataFinal(e.target.value)}
            value={dataFinal}
            max="2999-12-31"
          ></input>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={setarFiltros}>
            <i className="fas fa-search"></i>
          </button>
        </Col>
      </Row>
      <div>
        <RemoteTable
          dataId="id"
          url="/api/pontuacoes/codigo"
          exportar={{
            fileName: 'pontuacoes-codigo',
          }}
          filtros={filtros}
          colunas={columns}
          ordenacaoInicial={{ campo: 'dataUtilizacao', direcao: 'desc' }}
          onDataLoad={(data) => {
            console.log('Dados recebidos da API:', data);
          }}
        />
      </div>
    </Container>
  );
}
