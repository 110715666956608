import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  autenticacaoSucessoParceiro,
  autenticacaoSucessoUsuario,
  autenticacaoFalhouUsuario,
  autenticacaoFalhouParceiro,
  atualizarAntifraude,
  autenticacaoSucessoUsuarioRelatorio,
  autenticacaoFalhouUsuarioRelatorio,
} from './actions';
import historico from '../../../services/Historico';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export function* autenticarUsuario({ payload }) {
  try {
    const { cpf, senha } = payload;

    const response = yield call(api.post, '/api/sessoes/usuario', {
      cpf,
      senha,
    });

    const { redesAntifraude, token, usuario } = response.data;

    api.defaults.headers.Authorization = `|${token}|`;

    yield put(autenticacaoSucessoUsuario(token, usuario));

    const destino = usuario.permissao === 2 ? '/painel/resgates' : '/painel/dashboard';
    historico.push(destino);
    
    toast.success(`Olá, ${usuario.nome}!`, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } catch (err) {
    yield put(autenticacaoFalhouUsuario());
  }
}

export function* autenticarParceiro({ payload }) {
  try {
    const { cpf, senha } = payload;

    const response = yield call(api.post, '/api/sessoes/parceiro', {
      cpf,
      senha,
    });

    const { token, parceiro } = response.data;

    if (parceiro.ativo == 1) {
      api.defaults.headers.Authorization = `${token}||`;
      // console.log(parceiro, 'parceiro2');
      yield put(autenticacaoSucessoParceiro(token, parceiro));

      historico.push('/home');
      toast.success(`Olá, ${parceiro.nome}!`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      throw new Error('Parceiro não tem permissão para acessar a loja.');
    }
  } catch (err) {
    yield put(autenticacaoFalhouParceiro());
  }
}

export function* autenticarParceiroSocial({ payload }) {
  const { social_token, index } = payload;

  try {
    const response = yield call(api.post, '/api/sessoes/parceiro', {
      [index]: social_token,
    });

    if (response.response /* É um erro */) {
      if (response.response.status == 400) {
        let query = [];

        for (const index in response.response.data.social) {
          query.push(`${index}=${response.response.data.social[index]}`);
        }

        historico.push('/cadastrar?' + query.join('&'));
        return;
      }
    }

    if (response && response.data) {
      const { token, parceiro } = response.data;

      if (parceiro.ativo == 1) {
        api.defaults.headers.Authorization = `${token}||`;
        console.log(parceiro, 'parceiro2');
        yield put(autenticacaoSucessoParceiro(token, parceiro));

        historico.push('/loja');
        toast.success(`Olá, ${parceiro.nome}!`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        throw new Error('Parceiro não tem permissão para acessar a loja.');
      }
    }
  } catch (err) {
    console.log(`aconteceu um erro`, err);
    yield put(autenticacaoFalhouParceiro());
  }
}

export function* autenticarUsuarioRelatorio({ payload }) {
  try {
    const { cnpj, senha } = payload;

    const response = yield call(api.post, '/api/sessoes/usuario/relatorio', {
      cnpj,
      senha,
    });

    const { token, usuario } = response.data;

    api.defaults.headers.Authorization = `||${token}`;

    yield put(autenticacaoSucessoUsuarioRelatorio(token, usuario));

    historico.push('/relatorio/dashboard');
    toast.success(`Olá, ${usuario.nome}!`, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } catch (err) {
    yield put(autenticacaoFalhouUsuarioRelatorio());
  }
}

export function setarToken({ payload }) {
  if (!payload) return;
  const { tokenPainel, tokenLoja, tokenPainelRelatorio } = payload.autenticacao;

  api.defaults.headers.Authorization = `${tokenLoja}|${tokenPainel}|${tokenPainelRelatorio}`;
}

export function atualizarAntifraudePosLiberacao({ payload }) {
  if (!payload) return;
  const { redesAntifraude } = payload;
  put(atualizarAntifraude(redesAntifraude));
}

export function desautenticarUsuario() {
  historico.push('/painel');
  toast.success(`Até mais!`, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
}

export function desautenticarParceiro() {
  historico.push('/');
  toast.success(`Até mais!`, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
}

export function desautenticarUsuarioRelatorio() {
  historico.push('/relatorio');
  toast.success(`Até mais!`, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
}

export function desautenticarTodos() {
  if (historico.location.pathname.includes('painel')) {
    historico.push('/painel');
  } else if (historico.location.pathname.includes('relatorio')) {
    historico.push('/relatorio');
  } else {
    historico.push('/');
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setarToken),
  takeLatest('@autenticacaoUsuario/AUTENTICAR', autenticarUsuario),
  takeLatest('@autenticacaoUsuario/DESAUTENTICAR', desautenticarUsuario),
  takeLatest('@autenticacaoParceiro/AUTENTICAR', autenticarParceiro),
  takeLatest(
    '@autenticacaoParceiro/AUTENTICAR_REDESOCIAL',
    autenticarParceiroSocial
  ),
  takeLatest('@autenticacaoParceiro/DESAUTENTICAR', desautenticarParceiro),
  takeLatest(
    '@autenticacaoUsuarioRelatorio/AUTENTICAR',
    autenticarUsuarioRelatorio
  ),
  takeLatest(
    '@autenticacaoUsuarioRelatorio/DESAUTENTICAR',
    desautenticarUsuarioRelatorio
  ),
  takeLatest(
    '@autenticacaoUsuario/ATUALIZAR_ANTIFRAUDE',
    atualizarAntifraudePosLiberacao
  ),
  takeLatest('@autenticacao/DESAUTENTICAR', desautenticarTodos),
]);
