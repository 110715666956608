import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function CadastrarPremio() {
  const [enviando, setEnviando] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [custo, setCusto] = useState('');
  const [tipo, setTipo] = useState('');
  const [tags, setTags] = useState('');
  const [foto, setFoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [status, setStatus] = useState('Procurar...');

  const limparCampos = () => {
    setCodigo('');
    setNome('');
    setDescricao('');
    setTipo('');
    setTags('');
    setFoto(null);
    setPreview(null);
    setCusto('');
    setStatus('Procurar...');
  };

  const alterarFoto = async (e) => {
    if (e.target.files.length > 0) {
      setStatus(`${e.target.files[0].name}`);
      setFoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      setStatus('Procurar...');
      setPreview(null);
    }
  };

  const buscarCodigo = async (element) => {
    let codigo = element.target.value;
    if (!codigo) return;
    const resultado = await api.get(`/api/premios?codigo=${codigo}`);
    if (resultado.data.premios.length > 0) {
      toast.error('Código já cadastrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCodigo('');
    }
  };

  const cadastrarPremio = async () => {
    if (!nome) {
      toast.error('Informe o nome', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!codigo) {
      toast.error('Informe o código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!descricao) {
      toast.error('Informe a descrição', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!custo || isNaN(custo) || Number(custo) <= 0) {
      toast.error('Informe um valor em pontos válido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!foto) {
      toast.error('Selecione uma foto', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);

    let formData = new FormData();
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('codigo', codigo);
    formData.append('custo', Number(custo) / 100);
    formData.append('tipo', tipo === 'geral' ? '' : tipo);
    formData.append('tags', tags);
    formData.append('foto', foto);

    try {
      const resultado = await api.post('/api/premios', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (resultado?.data) {
        toast.success('Prêmio cadastrado com sucesso', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        limparCampos();
      } else {
        throw new Error('Erro ao cadastrar prêmio');
      }
    } catch (error) {
      toast.error('Erro ao cadastrar prêmio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setEnviando(false);
    }
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Prêmio</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Form className="form" onSubmit={cadastrarPremio}>
          <label htmlFor="foto">Foto</label>
          <input
            type="file"
            id="foto"
            name="foto"
            style={{ display: 'none' }}
            onChange={alterarFoto}
          />
          <input
            className="text-left"
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('foto').click();
            }}
          />
          {preview && (
            <img alt="Preview" src={preview} className="mt-3 mb-3 w-100" />
          )}
          <label htmlFor="codigo">Código</label>
          <Input
            id="codigo"
            name="codigo"
            type="text"
            placeholder="Código"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            onBlur={buscarCodigo}
          ></Input>
          <label htmlFor="nome">Nome</label>
          <Input
            id="nome"
            name="nome"
            type="text"
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          ></Input>
          <label htmlFor="descricao">Descrição</label>
          <textarea
            id="descricao"
            name="descricao"
            placeholder="Descrição"
            className="premio-desc p-2"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          ></textarea>
          <label htmlFor="custo">Valor em Pontos</label>
          <Input
            id="custo"
            name="custo"
            type="number"
            step="0.01"
            min="0"
            placeholder="150"
            value={custo}
            onChange={(e) => setCusto(e.target.value)}
          />
          <label htmlFor="tipo">Tipo</label>
          <select
            id="tipo"
            name="tipo"
            placeholder="Tipo"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
          >
            <option value="">Selecione o tipo</option>
            <option value="geral">Geral</option>
            <option value="bivolt">Bivolt</option>
            <option value="credito">Crédito</option>
            <option value="recarga">Recarga</option>
          </select>
          <label htmlFor="tags">Tags (separadas por vírgula)</label>
          <Input
            id="tags"
            name="tags"
            type="text"
            placeholder="Ex: eletrodoméstico, cozinha, casa"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </Form>
      </div>
    </Container>
  );
}
