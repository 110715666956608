import React, { useEffect } from 'react';
import Menu from '../../../components/Painel/Menu';
import './styles.css';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import historico from '../../../services/Historico';
import Dashboard from '../../../components/Painel/Dashboard';
import CadastrarRede from '../../../components/Painel/CadastrarRede';
import CadastrarAdministrador from '../../../components/Painel/CadastrarAdministrador';
import CadastrarCodigoPromocional from '../../../components/Painel/CadastrarCodigoPromocional';
import Perfil from '../../../components/Painel/Perfil';
import CadastrarRevenda from '../../../components/Painel/CadastrarRevenda';
import CadastrarProduto from '../../../components/Painel/CadastrarProduto';
import CadastrarProdutoRevenda from '../../../components/Painel/CadastrarProdutoRevenda';
import CadastrarPesquisaMercado from '../../../components/Painel/CadastrarPesquisaMercado';
import CadastrarPremio from '../../../components/Painel/CadastrarPremio';
import UploadProduto from '../../../components/Painel/UploadProduto';
import UploadProdutoRevenda from '../../../components/Painel/UploadProdutoRevenda';
import UploadVendas from '../../../components/Painel/UploadVendas';
import Redes from '../../../components/Painel/Redes';
import Revendas from '../../../components/Painel/Revendas';
import NotificacoesPush from '../../../components/Painel/NotificacoesPush';
import ProdutosSW from '../../../components/Painel/ProdutosSW';
import Parceiros from '../../../components/Painel/Parceiros';
import Empresas from '../../../components/Painel/Empresas';
import Pesquisas from '../../../components/Painel/Pesquisas';
import Pontuacoes from '../../../components/Painel/Pontuacoes';
import DetalheParceiro from '../../../components/Painel/DetalheParceiro';
import DetalheRevenda from '../../../components/Painel/DetalheRevenda';
import PontuacoesPendente from '../../../components/Painel/PontuacoesPendente';
import NovaVenda from '../../../components/Painel/NovaVenda';
import Resgates from '../../../components/Painel/Resgates';
import UploadHistorico from '../../../components/Painel/UploadHistorico/index';
import PontuacoesEncerradas from '../../../components/Painel/PontuacoesEncerradas/index';
import Mensuracao from '../../../components/Painel/Mensuracao/index';
import TaxaSucesso from '../../../components/Painel/TaxaSucesso/index';
import Relatorio from '../../../components/Painel/Relatorio/index';
import Antifraude from '../../../components/Painel/Antifraude/index';
import Historico from '../../../components/Painel/Historico/index';
import Cartoes from '../../../components/Painel/Cartoes/index';
import UploadSaldoCartao from '../../../components/Painel/UploadSaldoCartao/index';
import ProdutosVendidos from '../../../components/Painel/ProdutosVendidos/index';
import AtualizarRelatorio from '../../../components/Painel/AtualizarRelatorio/index';
import CorrecaoPontuacao from '../../../components/Painel/CorrecaoPontuacao/index';
import UploadMidia from '../../../components/Painel/UploadMidia/index';
import Midias from '../../../components/Painel/Midias/index';
import CodigosPromocionais from '../../../components/Painel/CodigosPromocionais/index';
import Lojas from '../../../components/Painel/Lojas/index';
import Cursos from '../../../components/Painel/Cursos/index';
import Premios from '../../../components/Painel/Premios/index';

import HistoricoPontuacoes from '../../../components/Painel/HistoricoPontuacoes/index';
import Indicacoes from '../../../components/Painel/Indicacoes/index';
import Relatorios from '../../../components/Painel/Relatorios';
import RelatorioResgates from '../../../components/Painel/RelatorioResgates';
import Vouchers from '../../../components/Painel/Vouchers';
import CadastrarVoucher from '../../../components/Painel/CadastrarVoucher';
import SwRecomenda from '../../../components/Painel/SwRecomenda';
import Banners from '../../../components/Painel/Banners';
import CadastrarBanner from '../../../components/Painel/CadastrarBanner';
import VerbaMensal from '../../../components/Painel/VerbaMensal';
import ValorPonto from '../../../components/Painel/ValorPonto';
import Pontos from '../../../components/Painel/Pontos';
import PontuacoesCodigo from '../../../components/Painel/PontuacoesCodigo/index';
import CadastrarCodigo from '../../../components/Painel/CadastrarCodigo';
import ListaCodigoCadastro from '../../../components/Painel/ListaCodigoCadastro';



export default function Home() {
  let match = useRouteMatch();

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Painel';
  }, []);

  return (
    <>
      <Menu
        selecionarItem={(item) => {
          if (historico.location.pathname !== `/${item}`) {
            historico.push(`${match.path}${item}`);
          }
        }}
      />
      <main className="main-painel">
        <Switch>
          <Route
            exact
            path={`${match.path}/relatorios`}
            component={(props) => <Relatorios />}
          />
          <Route
            exact
            path={`${match.path}/dashboard`}
            component={(props) => <Dashboard />}
          />
          <Route
            exact
            path={`${match.path}/perfil`}
            component={(props) => <Perfil />}
          />
          <Route
            exact
            path={`${match.path}/cadastrar/rede`}
            component={(props) => <CadastrarRede />}
          />
          <Route
            exact
            path={`${match.path}/cadastrar/administrador`}
            component={(props) => <CadastrarAdministrador />}
          />
          <Route
            exact
            path={`${match.path}/cadastrar/revenda`}
            component={(props) => <CadastrarRevenda />}
          />

<Route
            exact
            path={`${match.path}/cadastrar/premio`}
            component={(props) => <CadastrarPremio />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/produto`}
            component={(props) => <CadastrarProduto />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/produto/sherwin-williams`}
            component={(props) => <CadastrarProduto />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/produto/revenda`}
            component={(props) => <CadastrarProdutoRevenda />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/pesquisa-mercado`}
            component={(props) => <CadastrarPesquisaMercado />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/codigo-promocional`}
            component={(props) => <CadastrarCodigoPromocional />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/codigo-cadastro`}
            component={(props) => <CadastrarCodigo />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/voucher`}
            component={(props) => <CadastrarVoucher />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/banner`}
            component={(props) => <CadastrarBanner />}
          />

          <Route
            exact
            path={`${match.path}/processar/produtos`}
            component={(props) => <UploadProduto />}
          />

          <Route
            exact
            path={`${match.path}/processar/produtos/sherwin-williams`}
            component={(props) => <UploadProduto />}
          />

          <Route
            exact
            path={`${match.path}/processar/produtos/revenda`}
            component={(props) => <UploadProdutoRevenda />}
          />

          <Route
            exact
            path={`${match.path}/processar/vendas`}
            component={(props) => <UploadVendas />}
          />

          <Route
            exact
            path={`${match.path}/processar/historico`}
            component={(props) => <UploadHistorico />}
          />

          <Route
            exact
            path={`${match.path}/redes`}
            component={(props) => <Redes />}
          />

          <Route
            exact
            path={`${match.path}/revendas`}
            component={(props) => <Revendas />}
          />

          <Route
            exact
            path={`${match.path}/produtos`}
            component={(props) => <ProdutosSW />}
          />

          <Route
            exact
            path={`${match.path}/premios`}
            component={(props) => <Premios />}
          />

          <Route
            exact
            path={`${match.path}/pintores`}
            component={(props) => <Parceiros />}
          />

          <Route
            exact
            path={`${match.path}/empresas`}
            component={(props) => <Empresas />}
          />

          <Route
            exact
            path={`${match.path}/pesquisa-de-mercado`}
            component={(props) => <Pesquisas />}
          />

          <Route
            exact
            path={`${match.path}/pontuacoes/pontuado`}
            component={(props) => <Pontuacoes />}
          />

          <Route
            exact
            path={`${match.path}/pontuacoes/nao-pontuavel`}
            component={(props) => <PontuacoesEncerradas />}
          />

          <Route
            exact
            path={`${match.path}/pontuacoes/historico`}
            component={(props) => <HistoricoPontuacoes />}
          />

          <Route
            exact
            path={`${match.path}/pintor/:cpf`}
            component={(props) => <DetalheParceiro />}
          />

          <Route
            exact
            path={`${match.path}/revenda/:cnpj`}
            component={(props) => <DetalheRevenda />}
          />

          <Route
            exact
            path={`${match.path}/processar/pontuacoes`}
            component={(props) => <PontuacoesPendente />}
          />

          <Route
            exact
            path={`${match.path}/processar/nova-venda`}
            component={(props) => <NovaVenda />}
          />

          <Route
            exact
            path={`${match.path}/processar/relatorio`}
            component={(props) => <AtualizarRelatorio />}
          />

          <Route
            exact
            path={`${match.path}/processar/correcao-pontuacao`}
            component={(props) => <CorrecaoPontuacao />}
          />

          <Route
            exact
            path={`${match.path}/resgates`}
            component={(props) => <Resgates />}
          />

          <Route
            exact
            path={`${match.path}/notificacoes-push`}
            component={(props) => <NotificacoesPush />}
          />

          <Route
            exact
            path={`${match.path}/mensuracao`}
            component={(props) => <Mensuracao />}
          />

          <Route
            path={`${match.path}/taxa-sucesso/:mesesLimite`}
            component={(props) => <TaxaSucesso />}
          />

          <Route
            exact
            path={`${match.path}/taxa-sucesso`}
            component={(props) => <TaxaSucesso />}
          />

          <Route
            exact
            path={`${match.path}/relatorio`}
            component={(props) => <Relatorio />}
          />
          <Route
            exact
            path={`${match.path}/relatorio/resgate`}
            component={(props) => <RelatorioResgates />}
          />

          <Route
            exact
            path={`${match.path}/antifraude`}
            component={(props) => <Antifraude />}
          />

          <Route
            exact
            path={`${match.path}/historico`}
            component={(props) => <Historico />}
          />

          <Route
            exact
            path={`${match.path}/cartoes`}
            component={(props) => <Cartoes />}
          />

          <Route
            exact
            path={`${match.path}/processar/saldo-cartao`}
            component={(props) => <UploadSaldoCartao />}
          />

          <Route
            exact
            path={`${match.path}/produtos-vendidos`}
            component={(props) => <ProdutosVendidos />}
          />

          <Route
            exact
            path={`${match.path}/processar/foto-video`}
            component={(props) => <UploadMidia />}
          />

          <Route
            exact
            path={`${match.path}/fotos-videos`}
            component={(props) => <Midias />}
          />

          <Route
            exact
            path={`${match.path}/codigos-promocionais`}
            component={(props) => <CodigosPromocionais />}
          />

          <Route
            exact
            path={`${match.path}/codigos/codigo`}
            component={(props) => <PontuacoesCodigo />}
          />

          <Route
            exact
            path={`${match.path}/codigos/lista-codigo-cadastro`}
            component={(props) => <ListaCodigoCadastro />}
          />

          <Route
            exact
            path={`${match.path}/indicacoes`}
            component={(props) => <Indicacoes />}
          />

          <Route
            exact
            path={`${match.path}/lojas`}
            component={(props) => <Lojas />}
          />

          <Route
            exact
            path={`${match.path}/cursos`}
            component={(props) => <Cursos />}
          />

          <Route
            exact
            path={`${match.path}/vouchers`}
            component={(props) => <Vouchers />}
          />

          <Route
            exact
            path={`${match.path}/banners`}
            component={(props) => <Banners />}
          />

          <Route
            exact
            path={`${match.path}/verba-mensal`}
            component={(props) => <VerbaMensal />}
          />

          <Route
            exact
            path={`${match.path}/valor-ponto`}
            component={(props) => <ValorPonto />}
          />
          {/* <Route
            exact
            path={`${match.path}/sw-recomenda`}
            component={(props) => <SwRecomenda />}
          /> */}
          <Route
            exact
            path={`${match.path}/processar/pontos`}
            component={(props) => <Pontos />}
          />
        </Switch>
      </main>
    </>
  );
}
